
import { reactive, defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import IllustrationDinner from '@/components/illustrations/IllustrationDinner.vue';
import { functions, auth } from "@/firebase";
import router from "@/router/index";

export default defineComponent({
  components: {
    BaseButton,
    BaseInput,
    IllustrationDinner,
  },

  setup() {
    const state = reactive({
      isLoginForm: false,
      email: "",
      password: "",
      signUpError: false,
    });

    function submitLoginForm(): void {
      auth.signInWithEmailAndPassword(state.email, state.password)
        .then(() => {
          router.push('about');
        })
        .catch(err => console.log(err))
    }

    const submissionCallable = functions.httpsCallable("checkUserSignUp");
    function submitSignUpForm(): void {
      state.signUpError = false;
      submissionCallable({
        email: state.email,
        password: state.password,
      })
        .then((res) => {
          if (res.data === `Success`) {
            submitLoginForm();
          }
        })
        .catch((err) => {
          state.signUpError = true;
        });
    };

    function switchActionType(): void {
      state.signUpError = false;
      state.isLoginForm = !state.isLoginForm;
    }

    return {
      state,
      switchActionType,
      submitSignUpForm,
      submitLoginForm,
    };
  },
});
