<template>
	<div class="start">
    <section class="start__column m--start">
      <h1 class="start__heading o-heading">Family Dinner</h1>
      <h2 class="start__subheading">The Basar Family Network</h2>
      <IllustrationDinner />
    </section>
    <section class="start__column">
      <section class="start__content">
        <p class="o-copy">
          In order to sign up, or log in, you need to request access first. If you have already done so, you're good to go! If you have not, please <router-link to="request-access">request access</router-link> first.
        </p>
        <p class="o-copy">
          {{ state.isLoginForm ? 'If you have not yet signed up' : 'If you have already signed up' }}, you can <span class="start__toggle" @click="switchActionType">{{ state.isLoginForm ? 'sign up' : 'log in' }}</span>
        </p>
      </section>
      <section class="start__form">
        <BaseInput v-model="state.email">Email</BaseInput>
        <BaseInput v-model="state.password" input-type="password">Password</BaseInput>
        <p
          v-if="state.signUpError"
          class="o-copy start__submission-error"
        >
          There was an error signing you up. If you have already requested access, and signed up, try <span class="start__toggle" @click="state.isLoginForm = true">logging in</span> instead.
        </p>
        <section class="start__form-actions">
          <BaseButton
            v-if="!state.isLoginForm"
            @click="submitSignUpForm"
          >Sign Up</BaseButton>
          <BaseButton
            v-if="state.isLoginForm"
            @click="submitLoginForm"
          >Log In</BaseButton>
        </section>
      </section>
    </section>
	</div>
</template>

<script lang="ts">
import { reactive, defineComponent } from "vue";
import BaseButton from "@/components/BaseButton.vue";
import BaseInput from "@/components/BaseInput.vue";
import IllustrationDinner from '@/components/illustrations/IllustrationDinner.vue';
import { functions, auth } from "@/firebase";
import router from "@/router/index";

export default defineComponent({
  components: {
    BaseButton,
    BaseInput,
    IllustrationDinner,
  },

  setup() {
    const state = reactive({
      isLoginForm: false,
      email: "",
      password: "",
      signUpError: false,
    });

    function submitLoginForm(): void {
      auth.signInWithEmailAndPassword(state.email, state.password)
        .then(() => {
          router.push('about');
        })
        .catch(err => console.log(err))
    }

    const submissionCallable = functions.httpsCallable("checkUserSignUp");
    function submitSignUpForm(): void {
      state.signUpError = false;
      submissionCallable({
        email: state.email,
        password: state.password,
      })
        .then((res) => {
          if (res.data === `Success`) {
            submitLoginForm();
          }
        })
        .catch((err) => {
          state.signUpError = true;
        });
    };

    function switchActionType(): void {
      state.signUpError = false;
      state.isLoginForm = !state.isLoginForm;
    }

    return {
      state,
      switchActionType,
      submitSignUpForm,
      submitLoginForm,
    };
  },
});
</script>

<style lang="scss" scoped>
.start {
  display: grid;
  grid-template: 1fr / 1fr minmax(350px, 1fr);
  gap: 5%;
  align-items: center;
  max-width: 1366px;
  margin: 0 auto;
  height: 100%;
  @media (max-width: 780px) {
    grid-template: auto 1fr / 1fr;
    justify-content: center;
  }
  &__heading {
    margin: 0 0 0.5rem;
  }
  &__subheading {
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin: 0 0 1.5rem;
  }
  &__column {
    &.m {
      &--start {
        @media (max-width: 780px) {
          display: grid;
          grid-template: auto / minmax(275px, 350px);
          place-items: center;
          place-self: center;
        }
      }
    }
  }
  &__toggle {
    cursor: pointer;
    font-weight: 700;
    color: var(--primaryAlt);
    text-decoration: underline;
  }
  &__form {
    &-actions {
      margin-top: 3rem;
    }
  }
  &__submission-error {
    color: var(--warn);
  }
}
</style>